<main>
  <div class="container">

    <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <h1>401</h1>
      <h2>Désolé vous n'êtes pas autorisé à acceder à cette page.</h2>
      <a class="btn" href="/">Retourner à la page de connexion</a>
      <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found">
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ -->
        Designed by <a href="https://bogal.ca">Bogal Consulting</a>
      </div>
    </section>

  </div>
</main><!-- End #main -->
