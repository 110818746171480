import { Video } from "@Models/video.model";

export interface VideoState {
    lastUpdate: string;
    loading: boolean;
    errorMessage: string;
    successMessage: string;
    payload: {
        video: Video | undefined
        videos: Video[]
    };
};

export const initialVideoState: VideoState = {
    lastUpdate: new Date().toString(),
    loading: false,
    errorMessage: "",
    successMessage: "",
    payload: {
        video: undefined,
        videos: []
    },
};
