import { Account } from '@Models/account.model';
import { Credential, CredentialPassword } from 'src/app/core/store/states/auth.state';
import { Action } from '@ngrx/store';

export enum AccountActionTypes {
    GET_ACCOUNTS = '[Account] Get accounts',
    GET_ACCOUNTS_SUCCESS = '[Account] Successful get accounts',
    GET_ACCOUNT = '[Account] Get account',
    GET_ACCOUNT_SUCCESS = '[Account] Successful get account',
    UPDATE_ACCOUNT = '[Account] Update account',
    UPDATE_ACCOUNT_SUCCESS = '[Account] Successful update account',
    UPDATE_ACCOUNT_EMAIL = '[Account] Update email account',
    UPDATE_ACCOUNT_EMAIL_SUCCESS = '[Account] Successful update email account',
    UPDATE_ACCOUNT_PASSWORD = '[Account] Update password account',
    UPDATE_ACCOUNT_PASSWORD_SUCCESS = '[Account] Successful update password account',
    DELETE_ALL_DATA_ACCOUNT = '[Account] Delete all data account',
    DELETE_ALL_DATA_ACCOUNT_SUCCESS = '[Account] Successful delete all data account',
    ERROR_ACCOUNT = '[Account] Error account',
}

// Définir le type de l'action
export class GetAccounts implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNTS;
}

export class GetAccountsSuccess implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNTS_SUCCESS;
    constructor(public payload: Account[]) {}
}

export class GetAccount implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNT;
    constructor(public payload: string) {}
}

export class GetAccountSuccess implements Action {
    readonly type = AccountActionTypes.GET_ACCOUNT_SUCCESS;
    constructor(public payload: Account) {}
}

export class UpdateAccount implements Action {
    readonly type = AccountActionTypes.UPDATE_ACCOUNT;
    constructor(public payload: Account) {}
}

export class UpdateAccountSuccess implements Action {
    readonly type = AccountActionTypes.UPDATE_ACCOUNT_SUCCESS;
    constructor(public payload: string) {}
}

export class UpdateEmailAccount implements Action {
    readonly type = AccountActionTypes.UPDATE_ACCOUNT_EMAIL;
    constructor(public payload: Credential) {}
}

export class UpdateEmailAccountSuccess implements Action {
    readonly type = AccountActionTypes.UPDATE_ACCOUNT_EMAIL_SUCCESS;
    constructor(public payload: string) {}
}

export class UpdatePasswordAccount implements Action {
    readonly type = AccountActionTypes.UPDATE_ACCOUNT_PASSWORD;
    constructor(public payload: CredentialPassword) {}
}

export class UpdatePasswordAccountSuccess implements Action {
    readonly type = AccountActionTypes.UPDATE_ACCOUNT_PASSWORD_SUCCESS;
    constructor(public payload: string) {}
}

export class DeleteAllDataAccount implements Action {
    readonly type = AccountActionTypes.DELETE_ALL_DATA_ACCOUNT;
    constructor(public payload: string) {}
}

export class DeleteAllDataAccountSuccess implements Action {
    readonly type = AccountActionTypes.DELETE_ALL_DATA_ACCOUNT_SUCCESS;
    constructor(public payload: string) {}
}

export class ErrorAccount implements Action {
    readonly type = AccountActionTypes.ERROR_ACCOUNT;
    constructor(public payload: string) {}
}

export type AuthActions = GetAccounts |
    GetAccountsSuccess |
    UpdateEmailAccount |
    UpdateEmailAccountSuccess |
    GetAccount |
    GetAccountSuccess |
    UpdateAccount |
    UpdateAccountSuccess |
    UpdatePasswordAccount |
    UpdatePasswordAccountSuccess |
    DeleteAllDataAccount |
    DeleteAllDataAccountSuccess |
    ErrorAccount;
