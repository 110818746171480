import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
      private afAuth: AngularFireAuth,
      private router: Router
  ){ }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          return new Promise((resolve) => {
              this.afAuth.authState.subscribe((result) => {
                  if (result) {
                      resolve(true);
                  } else {
                      this.router.navigate(['/access-denied']);
                      resolve(false);
                  }
              },
              error => {
                  this.router.navigate(['/access-denied']);
                  resolve(false);
              });
          });
  }
}
