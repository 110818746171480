import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, catchError, of, tap } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { AccountService } from "@Services/account.service";
import { AccountActionTypes, DeleteAllDataAccountSuccess, ErrorAccount, GetAccountsSuccess, GetAccountSuccess, UpdateAccountSuccess, UpdateEmailAccountSuccess, UpdatePasswordAccountSuccess } from "../actions/account.action";
import { Account } from "@Models/account.model";
import { FirebaseError } from "firebase/app";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "@Services/auth.service";


@Injectable()
export class AccountEffects {

    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private toastrService: ToastrService,
        private authService: AuthService,
        private logger: NGXLogger,
    ) {}

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActionTypes.GET_ACCOUNTS),
            mergeMap((action: any) => {
                return this.accountService.getAccounts()
                    .pipe(
                        map((accounts: Account[]) => {
                            this.logger.info('Liste des utilisateurs', accounts.length);
                            return new GetAccountsSuccess(accounts);
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error('login fail', err.code, err.message);
                            return of(new ErrorAccount("Une erreur est survenue lors du chargement des utilisateurs"));
                        })
                    )
            })
        )
    );

    accountById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActionTypes.GET_ACCOUNT),
            mergeMap((action: any) => {
                return this.accountService.getAccountById(action.payload)
                    .pipe(
                        map((account: Account) => {
                            this.logger.info('Utilisateur trouvé', account);
                            return new GetAccountSuccess(account);
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error('Account not found', err.code, err.message);
                            return of(new ErrorAccount("Aucun utilisateur à été trouvé avec ce compte"));
                        })
                    )
            })
        )
    );

    updateAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActionTypes.UPDATE_ACCOUNT),
            mergeMap((action: any) => {
                return this.accountService.updateAccount(action.payload)
                    .pipe(
                        map((account: Account) => {
                            this.logger.info('Modification complétée', action.payload);
                            return new UpdateAccountSuccess('Modification réussie');
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error('Une erreur est survenue lors de la modification du compte', err.code, err.message);
                            return of(new ErrorAccount("Une erreur est survenue lors de la modification du compte"));
                        })
                    )
            })
        )
    );

    updateAccountSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActionTypes.UPDATE_ACCOUNT_SUCCESS),
        tap((action: any) => this.toastrService.success(action.payload))
    ), { dispatch: false });

    updateEmailAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActionTypes.UPDATE_ACCOUNT_EMAIL),
            mergeMap((action: any) => {
                const {email, password} = action.payload;
                return this.accountService.updateAccountEmail(password, email)
                    .pipe(
                        map((account: Account) => {
                            this.logger.info('Modification complétée', action.payload);
                            return new UpdateEmailAccountSuccess('Modification de votre email réussie');
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error('Une erreur est survenue lors de la modification du compte', err.message);
                            const messageType = ["auth/invalid-email", "auth/wrong-password", "auth/email-already-in-use"];
                            const messageTmp = err.message;
                            let message = "Une erreur est survenue lors de la modification du compte";
                            
                            messageType.find((item) => {
                                if(messageTmp.includes(item)) {
                                    if(item == "auth/invalid-email") {
                                        message = "L'email utilisé est invalide";
                                    }
                                    if(item == "auth/wrong-password") {
                                        message = "Le mot de passe utilisé est invalide";
                                    }
                                    if(item == "auth/email-already-in-use") {
                                        message = "Cette adresse email existe déjà";
                                    }
                                }
                            });

                            return of(new ErrorAccount(message));
                        })
                    )
            })
        )
    );

    updateEmailAccountSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActionTypes.UPDATE_ACCOUNT_EMAIL_SUCCESS),
        tap((action: any) => this.toastrService.success(action.payload))
    ), { dispatch: false });

    updatePasswordAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActionTypes.UPDATE_ACCOUNT_PASSWORD),
            mergeMap((action: any) => {
                const {password, currentPassword} = action.payload;
                return this.authService.updateAccountPassword(currentPassword, password)
                    .pipe(
                        map((account: Account) => {
                            this.logger.info("Modification du mot de passe complétée");
                            return new UpdatePasswordAccountSuccess("Modification de votre mot de passe réussie");
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error("Une erreur est survenue lors de la modification du mot de passe du compte", err.message);
                            return of(new ErrorAccount("Une erreur est survenue lors de la modification du mot de passe du compte"));
                        })
                    )
            })
        )
    );

    updatePasswordAccountSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActionTypes.UPDATE_ACCOUNT_PASSWORD_SUCCESS),
        tap((action: any) => this.toastrService.success(action.payload))
    ), { dispatch: false });

    deleteAllDataFromAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActionTypes.DELETE_ALL_DATA_ACCOUNT),
            mergeMap((action: any) => {
                return this.accountService.deleteAllDataFromAccount(action.payload)
                    .pipe(
                        map(([audioResult, videoResult]) => {
                            this.logger.info("Suppression des audios et videos du compte", action.payload, audioResult, videoResult);
                            return new DeleteAllDataAccountSuccess("Supression de vos données réussie");
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error("Une erreur est survenue lors de la supression de vos données", err.message);
                            return of(new ErrorAccount("Une erreur est survenue lors de la supression de vos données"));
                        })
                    )
            })
        )
    );

    deleteAllDataFromAccountSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActionTypes.DELETE_ALL_DATA_ACCOUNT_SUCCESS),
        tap((action: any) => this.toastrService.success(action.payload))
    ), { dispatch: false });

    errorAccount$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActionTypes.ERROR_ACCOUNT),
        tap((action: any) => this.toastrService.error(action.payload))
    ), { dispatch: false });
}