import { VideoState, initialVideoState } from "@States/video.state";
import { VideoActionTypes } from "@Actions/video.action";

export function VideoReducer(
    state = initialVideoState,
    action: any
): VideoState {
    switch (action.type) {
        case VideoActionTypes.LIST_VIDEO: {
            return {
                ...state,
                loading: true,
            };
        }

        case VideoActionTypes.LIST_VIDEO_SUCCESS: {
            return {
                ...state,
                loading: false,
                payload: {
                    video: undefined,
                    videos: action.payload
                }
            };
        }

        case VideoActionTypes.UPDATE_VIDEO: {
            return {
                ...state,
                loading: true
            };
        }

        case VideoActionTypes.UPDATE_VIDEO_SUCCESS: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            };
        }
        

        default: {
            return state;
        }
    }
}