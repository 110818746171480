import { Account } from "@Models/account.model";

export interface Credential {
    email: string;
    password: string;
};

export interface CredentialPassword {
    password: string;
    currentPassword: string;
};

export interface AuthState {
    lastUpdate: string;
    loading: boolean;
    errorMessage: string;
    successMessage: string;
    payload: {
        credentials: Credential | CredentialPassword | null,
        account: Account | null
    };
};

export const initialAuthState: AuthState = {
    lastUpdate: new Date().toString(),
    loading: false,
    errorMessage: "",
    successMessage: "",
    payload: {
        credentials: null,
        account: null
    },
};