<!--<mat-drawer-container>-->
<!--  <mat-drawer mode="side" [opened]="sideBarOpen">-->
<!--    &lt;!&ndash; <mat-drawer mode="side" opened="true"> &ndash;&gt;-->
<!--    <app-sidenav></app-sidenav>-->
<!--  </mat-drawer>-->
<!--  <mat-drawer-content>-->
<!--    <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>-->
<!--    <router-outlet></router-outlet>-->
<!--  </mat-drawer-content>-->
<!--</mat-drawer-container> -->
<app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>
<app-sidenav></app-sidenav>
<main id="main" class="main">
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>


