import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule, USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { AngularFireAuthModule, USE_DEVICE_LANGUAGE, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';

import { Action, ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from 'src/app/core/store/effects/auth.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthState } from '@States/auth.state';
import { AccountReducer } from '@Reducers/account.reducer';
import { authReducer } from '@Reducers/auth.reducer';
import { AccountState } from '@States/account.state';
import { AccountEffects } from '@Effects/account.effects';
import { AudioReducer } from '@Reducers/audio.reducer';
import { AudioState } from '@States/audio.state';
import { VideoState } from '@States/video.state';
import { VideoReducer } from '@Reducers/video.reducer';
import { AudioEffects } from '@Effects/audio.effects';
import { VideoEffects } from '@Effects/video.effects';
import { SharedModule } from './main/common/shared.module';
import { AuthLayoutComponent } from './main/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './main/layouts/main-layout/main-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireFunctionsModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      progressBar: true
    }),
    StoreModule.forRoot({
      auth: authReducer as ActionReducer<AuthState, Action>,
      account: AccountReducer as ActionReducer<AccountState, Action>,
      audio: AudioReducer as ActionReducer<AudioState, Action>,
      video: VideoReducer as ActionReducer<VideoState, Action>,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      AccountEffects,
      AudioEffects,
      VideoEffects,
    ]),
    StoreDevtoolsModule.instrument({ 
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    
  ],
  providers: [
    { provide: FIRESTORE_SETTINGS, useValue: { ignoreUndefinedProperties: true } },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    { provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9199] : undefined },
    { provide: USE_DEVICE_LANGUAGE, useValue: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }