import { Action } from '@ngrx/store';
import { Audio } from "@Models/audio.model";

export enum AudioActionTypes {
    LIST_AUDIO = "[Audio] List audios",
    LIST_AUDIO_SUCCESS = '[Audio] Successfully list audios',
    UPDATE_AUDIO = "[Audio] Audio update",
    UPDATE_AUDIO_SUCCESS = "[Audio] Successfully audio update",
    ERROR_AUDIO = '[Audio] Audio Error'
}

// Définir le type de l'action
export class ListAudio implements Action {
    readonly type = AudioActionTypes.LIST_AUDIO;
}

export class ListAudioSuccess implements Action {
    readonly type = AudioActionTypes.LIST_AUDIO_SUCCESS;

    constructor(public payload: Audio[]) {}
}

export class UpdateAudio implements Action {
    readonly type = AudioActionTypes.UPDATE_AUDIO;

    constructor(public payload: Audio) {}
}

export class UpdateAudioSuccess implements Action {
    readonly type = AudioActionTypes.UPDATE_AUDIO_SUCCESS;

    constructor(public payload: string) {}
}

export class ErrorAudio implements Action {
    readonly type = AudioActionTypes.ERROR_AUDIO;
    
    constructor(public payload: string) {}
}


export type AudioActions = ListAudio | 
    ListAudioSuccess| 
    UpdateAudio | 
    UpdateAudioSuccess |
    ErrorAudio;
