import { Doc, defaultDoc } from "./doc.model";
import { GENDER_VALUE, ROLE_VALUE } from "./enums/account.enum";

export interface SimpleUser {
    id: string | null;
}

export interface Account extends Doc {
    email: string;
    firstName: string;
    lastName?: string | null;
    gender?: GENDER_VALUE | null;
    birthday?: string | null;
    roles: ROLE_VALUE[];
    password?: string;
}

export const defaultUser = {
    ...defaultDoc,
    email: '',
    firstName: '',
    roles: [],
} as Account;
