
import {Injectable} from '@angular/core';
import { Account, defaultUser } from '@Models/account.model';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {BehaviorSubject, from, Observable, of, switchMap} from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ROLE_VALUE } from '@Models/enums/account.enum';
import firebase from "@firebase/app-compat";
import { NGXLogger } from 'ngx-logger';
import { Const } from 'src/environments/const';
import userStorageHelper from '@Helpers/userStorage.helper';
import { FirebaseError } from 'firebase/app';


const ACCOUNT_PATH = Const.firebase.collections.account;

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {

    private loggedIn = new BehaviorSubject<any>(1);
    loggedIn$ = this.loggedIn.asObservable();

    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private logService: NGXLogger
    ) {
        super(afAuth, afs);
        //this.authStatusListener();
    }

    get currentUser$(): Observable<Account> {
        return this.angularFireAuth.authState.pipe(
            filter(user => !!user),
            switchMap(user => {
                this.getUserById(user!.uid).subscribe(
                    (data) => {
                        debugger

                    },
                    (err) => {
                        debugger
                    }
                )
                debugger
                return (user) ? this.getUserById(user!.uid) : of({} as Account);
            })
        );
    }

    getUserById(userId: string): Observable<Account> {
        return this.doc$<Account>(`${ACCOUNT_PATH}/${userId}`);
    }

    // Sign in with email/password
    signIn(email: string, password: string): Observable<any> {
        return from(this.afAuth.signInWithEmailAndPassword(email, password));
    }

    // Sign up with email/password
    signUp(account: Account): Observable<any> {
        const email = account.email;
        const password = account.password;

        return from(this.afAuth.createUserWithEmailAndPassword(email, password!));
    }

    reauthenticateUser(email: string, oldPassword: string): Promise<any> {
        const user = firebase.auth().currentUser;
        if (user) {
            const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
            return user.reauthenticateWithCredential(credential);
        } else {
            throw new Error('User not authenticated');
        }
    }

    changePassword(currentPassword: string, newPassword: string): Observable<void> {
        return from(new Promise<void>((resolve, reject) => {
            this.afAuth.currentUser
            .then(user => {
                if (user) {
                    this.reauthenticateUser(user.email!, currentPassword)
                        .then(() => {
                            return resolve(user.updatePassword(newPassword));
                        })
                        .catch((error: FirebaseError) => {
                            reject(error);
                        });
                } else {
                    reject(new Error('User not authenticated'));
                }
        }).catch((error: FirebaseError) => {
            reject(error);
        })}));
    }

    // Reset Forgot password
    forgotPassword(passwordResetEmail: string) {
        return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
    }

    verifyMail(code: string){
        return this.afAuth.applyActionCode(code);
    }

    confirmPassword(password: string, code: string){
        return this.afAuth.confirmPasswordReset(code, password);
    }

    createAccount(account: Account): Observable<any> {
        return from(this.col(ACCOUNT_PATH).doc(account.id!).set({
            ...defaultUser,
            ...account
        }));
    }

    // Sign out
    signOut(): Observable<void> {
        return from(this.afAuth.signOut());
    }

    //#region isRole
    //Determine if the user in parameter is admin
    get isCurrentUserHaveOneAllAdmin(): boolean {
        let result = false;
        let currentUser = this.currentUserWithLocalStorage;
        debugger

        if(currentUser != null || currentUser != undefined) {
            if(currentUser.roles.find(role => role == ROLE_VALUE.BCBC_ADMIN || role == ROLE_VALUE.BCBC_SUP_ADMIN)){
                result = true;
            }
        }

        return result;
    }

    get isCurrentUserAdmin(): boolean {
        let result = false;
        let currentUser = this.currentUserWithLocalStorage;

        if(currentUser != null || currentUser != undefined) {
            if(currentUser.roles.find(role => role == ROLE_VALUE.BCBC_ADMIN)){
                result = true;
            }
        }

        return result;
    }

    get isCurrentUserSupAdmin(): boolean {
        let result = false;
        let currentUser = this.currentUserWithLocalStorage;

        if(currentUser != null || currentUser != undefined) {
            if(currentUser.roles.find(role => role == ROLE_VALUE.BCBC_SUP_ADMIN)){
                result = true;
            }
        }

        return result;
    }

    //#endregion isRole

    get currentUserWithLocalStorage(): Account {
        return userStorageHelper.get<Account>(Const.app.user.localstorage!) as Account;
    }

    // Send email verficaiton when new user sign up
    sendVerificationMail() {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            return from(currentUser.sendEmailVerification());
        }
        return of(undefined);
    }

    //Determine if the current user is logged
    authStatusListener(){
        this.afAuth.onAuthStateChanged((credential: any)=>{

            if(credential && this.currentUserWithLocalStorage){
                this.loggedIn.next(credential);
                this.logService.info("Current user logged in", credential._delegate.email);
            }
            else {
                // wait 5 secondes and confirm user does not connected
                setTimeout(() =>{
                    this.loggedIn.next(null);
                    this.logService.warn("Current user disconnected");
                }, 5000)

            }
        });
    }

    updateAccountPassword(currentPassword: string, newPassword: string): Observable<any> {
        return from(new Promise<void>((resolve, reject) => {
            this.afAuth.currentUser
                .then((user: any) => {
                    if (user) {
                        const credential = firebase.auth.EmailAuthProvider.credential(user.email!, currentPassword);
                        this.logService.info("Successful password update", user);

                        user.reauthenticateWithCredential(credential)
                            .then(() => {
                                return user.updatePassword(newPassword)
                                    .then(() => {
                                        this.logService.info("reauthenticate With Credential Successful");
                                        resolve();
                                    })
                                    .catch((error: any) => {
                                        this.logService.error(`reauthenticate With Credential failed`, `${error.code}: ${error.message}`);
                                        reject(new Error('Failed to update password: ' + error.message));
                                    });
                            })
                            .catch((error: any) => {
                                this.logService.error(`failed to reconnect account`, `${error.code}: ${error.message}`, user);
                                reject(new Error('Failed to re-authenticate account: ' + error.message));
                            });
                    } else {
                        this.logService.error("Failed to change password. No user logged in");
                        reject(new Error('No authenticated user found.'));
                    }
                })
                .catch((error: any) => {
                    this.logService.error(`failed to update account`, `${error.code}: ${error.message}`);
                    reject(new Error('Failed to update account: ' + error.message));
                });
        }));
    }

    setAuthPersistance(status: string){
        return this.afAuth.setPersistence(status);
    }
}

