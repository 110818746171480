<div><br><br><br>
  <p><a href="user-conditions#french"><i>the following in french</i></a></p>

  <div class="justify-content-center">
    <h1>Terms and conditions of use
      Mobile application Bon Cop Bad Cop</h1>


    <p>THIS AGREEMENT LIMITS THE LIABILITY OF THE Bon Cop Bad Cop Application ("BCBC", "We", "Us", "Our", AS THE CONTEXT MAY BE), GRANTS YOU A LIMITED RIGHT OF USE, AND GIVES BCBC OWNERSHIP AND CONTROL OF ANY INFORMATION SUBMITTED BY YOU THROUGHOUT THIS APPLICATION.</p>

    <h3>General</h3>
    <p>  This document sets out the terms and conditions of use ("Terms") for you to use the Bon Cop Bad Cop application ("Application"). These Terms form a legally binding agreement ("Agreement") between you and BCBC. By clicking on the "Register" button, you confirm that you have read, understand and agree to be bound by the Terms and Conditions of the Agreement below. Please read the Terms and Conditions carefully before agreeing by downloading the Application. If you do not agree to these Terms, you may not install or use the Application.
    </p>

    <h3>Protection of your information</h3>
    <p>  In order to use certain components of the Application, you must register by creating an account with BCBC and providing certain information about yourself when requested to do so through the Application's registration form. We guarantee your anonymity by using only your username as a reference.
    </p>
    <p>
      Your privacy is very important to BCBC. To this end, we operate at all times in compliance with applicable laws and regulations. When you use the Application, any personal information you provide to BCBC will be used and protected in accordance with the requirements of the Greater Montreal Legal Clinic's Privacy and Confidentiality Policy, which can be found online at www.cjgm.ca.
    </p>

    <h3>  Intellectual property and licensing</h3>
    <p>
      This application is owned and operated by BCBC and the Greater Montreal Legal Clinic, located at 3830 Henri Bourassa Boulevard East, Montreal, Quebec, Canada, H1H 1K4.
      In consideration of your acceptance of these Terms, BCBC grants you a non-transferable, non-exclusive, revocable license to download, access and use the Application for your personal, non-commercial use, and for no other purpose. This license is conditioned upon your compliance with these Terms and Conditions.
      BCBC retains all trademark, copyright, database and other intellectual property rights in the Application and the underlying programming code.
    </p>

    <h3>Terms of use</h3>
    <p>  This Application is made available to you free of charge for use on your mobile device. By adhering to this Agreement and accessing and using this Application, including purchasing services through it, you represent that:</p>
    <ul>
      <li>  you with the legal capacity and right to enter into this Agreement, and to access and use this Application in accordance with this Agreement;
      </li>
      <li>  you are at least 13 years old;
      </li>
      <li>  you are not thereby in breach of any other agreement or obligation or any legal or regulatory provision;
      </li>
      <li>  you will use the Application in accordance with these Terms and Conditions;
      </li>
      <li>  all personal information you provide is accurate, current and complete;
      </li>
      <li>  you will protect your personal information and not disclose any username, password or other information provided to you to any third party, and that you will notify us, as soon as possible, if you become aware or suspect, for any reason, that the security of your account has been compromised; and
      </li>
      <li>  you accept full responsibility for all assessments, taxes, fees and duties and any other financial liability that may result from your use of the Application under your name or account, as well as any use under your name or account by any third party.
      </li>
    </ul>

    <h3>Restricted use</h3>
    <p>
      You may not access or use the Application for any purpose that is unlawful or prohibited by this Agreement. You may access or use the Application to visually or vocally record an encounter with a person in a position of authority, such as a police officer.
      This Application is governed by the laws of the Province of Quebec, the laws of Canada and any applicable legislation in that province. If the Application is used outside of Canada, we cannot guarantee the viability or admissibility of the evidence.
      Use of this Application for any other purpose, including commercial purposes, is strictly prohibited.
    </p>
    <p>
      Without limiting the generality of the foregoing, you agree that by accessing or using Our Application, you will not commit or participate in, or permit anyone to commit or participate on your behalf in, any of the following acts, each of which is expressly prohibited by this Agreement:
    </p>
    <ul>
      <li>    (a) make and distribute copies of the Application; (b) attempt to copy, reproduce, alter, modify, reverse engineer, disassemble, decompile or translate the Application; (c) create derivative works of the Application (d) attempt to extract the source code of the Application (e) remove, alter or destroy any trademark or copyright notices placed on or contained within the Application; or (f) use the Application to develop an application or technology that has the same primary function as Our Application;
      </li>
      <li>    engage in any other conduct or activity that conflicts with this Agreement or that BCBC, in its sole and absolute discretion, considers to be in conflict with this Agreement.
      </li>
    </ul>

    <h3>Application Platform</h3>
    <p>  This Application is only available on iPhone® mobile devices, as well as on Android® mobile devices.
    </p>
    <p>  You acknowledge and agree that the availability of the Application is dependent upon the platform provided by a third party such as the "Apple Store" for the iPhone ("the Platform"), through which you access the Application. You acknowledge that this Agreement is between you and BCBC and that there is no contractual relationship with the Platform. BCBC and the Greater Montreal Legal Clinic, and not the Platform, are solely responsible for the Application, its content, maintenance and support services, as well as any claims that may arise in connection therewith, such as product liability, regulatory compliance or infringement of intellectual property rights.
    </p>

    <h3>  Additional rules of use, rights and responsibilities specific to your mobile device
    </h3>
    <b>  Without limiting the generality of the foregoing, your use of the Application on any Apple® mobile device is subject to the usage rules established by Apple Inc. including the terms and conditions set forth in the App Store Terms and Conditions of Use available at
      <a href="http://www.apple.com/legal/itunes/ww/">http://www.apple.com/legal/itunes/ww/</a>, the applicable provisions of which are incorporated into the Agreement by this reference
    </b>

    <p>
      Without limiting the generality of the foregoing, your use of the Application with any Android® mobile device is subject to the usage rules established by Google Inc. including the terms and conditions set forth in the Android Market Terms available at
      <a href="https://play.google.com/intl/fr_lu/about/play-terms.html">https://play.google.com/intl/fr_lu/about/play-terms.html</a> , the applicable provisions of which are incorporated into the Agreement by this reference.
    </p>

    <h3>  Limit of liability
    </h3>
    <p>  While BCBC will take all reasonable steps under the circumstances to maintain the security of this Application and to protect your personal and confidential information provided by you, you agree that your use of this Application is entirely at your own risk. To the extent permitted by law, BCBC shall not be liable for (a) any errors or omissions in the content available on the Application and (b) any damages, direct, indirect, special, punitive or exemplary damages for loss or injury of any kind, including but not limited to damage to the mobile device, resulting from your access to or use of the Application. In the event that a court finds BCBC liable for any loss or damage, BCBC shall not be liable at any time for damages in excess of $250 CAD.
    </p>

    <h3>Exclusion of guarantees</h3>
    <p>  The Application is provided to you "as is" and "as available" without any representations, warranties, conditions or guarantees of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose or non-infringement. This exclusion does not apply where the law does not allow the exclusion of implied warranties. To the extent permitted by law, BCBC makes no representations or warranties that the Application will be (a) error free, complete or current; (b) always available; (c) meet your expectations; and (d) secure, free of errors, defects, viruses or other harmful content.
    </p>

    <h3>Compensation</h3>
    <p>  As a condition of your use of the Application, you agree to defend, indemnify and hold harmless BCBC, and any of its officers, directors, employees and agents, from and against any and all liabilities, losses, claims and expenses (including reasonable legal fees) arising out of or in connection with your use of the Application or your breach of this Agreement.
    </p>
    <p>  A delay, failure or omission by BCBC to exercise any right or power under this Agreement shall not be deemed a waiver by Us of any such right or power which may be exercised at any time and against any person.
    </p>

    <h3>  External links
    </h3>
    <p>  This Application may contain hyperlinks to other sites that are operated by third parties. These links are provided for reference purposes only. We have no control over their content or performance and are not responsible for their content or privacy practices. BCBC does not endorse or accept any responsibility for the material on these external sites, nor any association with their operators. When you click on a link, you should refer to their terms of use and privacy policy.
    </p>

    <h3>  Applicable laws and choice of domicile
    </h3>
    <p>  This Agreement shall in all respects be governed by and construed in accordance with the laws of the Province of Quebec, Canada, and the laws of Canada applicable therein, without regard to any statutes, rules or other conflict of laws (of the Province of Quebec or any other jurisdiction) that would result from the application of the laws of any jurisdiction other than the Province of Quebec.
    </p>
    <p>  You agree to submit to the jurisdiction of the courts of the judicial district of Montreal, in the province of Quebec, for any dispute or disagreement arising out of this Agreement. You waive any objection to the jurisdiction of such courts to interpret this Agreement and to hear any dispute arising hereunder by reason of inconvenient forum or otherwise, and to commence or file any action or complaint in any other court.
    </p>
    <p>  Nothing in this section shall prevent BCBC from commencing an action in any court of competent jurisdiction to enforce this Agreement and to seek damages or equitable relief following a breach by you of this Agreement.
    </p>

    <h3>  Account cancellation
    </h3>
    <p>  We reserve the right, in our sole discretion, to remove or modify the Application and/or prevent access to or withdraw the right to access the Application from any person at any time for any reason, including as a result of a breach of these Terms.
    </p>

    <h3>Changes</h3>
    <p>  BCBC reserves the right to change these Terms and Conditions at any time for any reason. When material changes are made, BCBC will post the revised terms and conditions of use on the Application. You will be deemed to have accepted them if you continue to use the Application after the revised Terms and Conditions have been posted.
    </p>

    <h3>  Full agreement
    </h3>
    <p>  Subject to any other applicable agreement to which BCBC may be a party, this is the entire agreement, contract and understanding between you and BCBC.
    </p>

    <h3>Dissociability</h3>
    <p>  If any portion of this Agreement shall be held invalid or unenforceable, then such portion shall not affect the validity or enforceability of the remainder of this Agreement which shall be enforced, and such invalid or unenforceable portion shall be severed from this Agreement.
    </p>

    <h3>  Contact us
    </h3>
    <p>  If you have any questions about the Application, or if you have any questions or suggestions for improvements to the Application, please contact us at {{emailCjgm}} or by locating our contact information on the website https://cjgm.ca/
    </p>

  </div>
  <div id="french">
    <h1>Modalités et conditions d’utilisation
      Application mobile Bon Cop Bad Cop</h1>


    <p>    CETTE ENTENTE LIMITE LA RESPONSABILITÉ DE l’Application Bon Cop Bad Cop. (« BCBC », « Nous », « Notre », SELON LE CONTEXTE), VOUS ACCORDE UN DROIT D’UTILISATION LIMITÉ, ET DONNE À BCBC LA PROPRIÉTÉ ET LE CONTRÔLE DE TOUTE INFORMATION SOUMISE PAR VOUS PAR L’ENTREMISE DE CETTE APPLICATION.
    </p>

    <h3>Généralités</h3>
<p>    Ce document définit les modalités et les conditions d’utilisation (« les Modalités ») vous permettant d’utiliser l’application de Bon Cop Bad Cop (« l’Application »). Ces Modalités forment un accord légalement contraignant (« l’Entente ») entre vous et BCBC. En cliquant sur le bouton « Enregistrer», vous confirmez avoir lu, compris et accepté d’être lié par les Modalités de l’Entente ci-dessous. Veuillez lire attentivement les Modalités avant de donner votre accord en téléchargeant l’Application. Si vous n’acceptez pas ces Modalités, vous ne pouvez pas installer ni utiliser l’Application.
</p>

    <h3>    Protection de vos renseignements
    </h3>
    <p>    Afin d’utiliser certaines composantes de l’Application, vous devez vous enregistrer en créant un compte avec BCBC et en fournissant certains renseignements à votre sujet lorsque requit de le faire par le formulaire d’enregistrement de l’Application. Nous garantissons votre anonymat en utilisant que votre nom d’utilisateur comme référence.
    </p>

    <p>    BCBC accorde une grande importance à la protection de votre vie privée. À cet effet, nous opérons en tout temps en conformité avec les lois et règlements applicables. Lorsque vous utilisez l’Application, tout renseignement personnel que vous fournirez à BCBC sera utilisé et protégé en conformité avec les exigences de la Politique de confidentialité et de protection des renseignements personnels de la Clinique juridique du Grand Montréal, laquelle peut être consultée en ligne : www.cjgm.ca.
    </p>

    <h3>    Propriété intellectuelle et licence
    </h3>
    <p>    Cette application est la propriété et est opérée par BCBC et la Clinique juridique du Grand Montréal, qui siègent au 3830, Boulevard Henri Bourassa Est, Montréal, Québec, Canada, H1H 1K4.
    </p>
    <p>    En contrepartie de votre acceptation aux présentes Modalités, BCBC vous octroie une licence non transmissible, non exclusive, incessible et révocable pour télécharger, accéder à et utiliser l’Application pour votre usage personnel et non commercial, et pour aucun autre usage. Cette licence est conditionnelle au respect des présentes Modalités.
    </p>
    <p>    BCBC conserve toute marque de commerce, tout droit d’auteur, droit sur les banques de données et tout autre droit de propriété intellectuelle en lien avec l’Application, de même que le code de programmation sous-jacent.
    </p>


    <h3>    Conditions d’utilisation
    </h3>
    <p>    Cette application est mise à votre disposition gratuitement pour utilisation sur votre appareil mobile. En adhérant à la présente Entente et en accédant et utilisant cette Application, incluant l’achat de services par le biais de celle-ci, vous représentez que :
    </p>
    <ul>
      <li>    vous avec la capacité légale et le droit d’adhérer à cette Entente, et d’accéder à et d’utiliser cette Application en conformité avec la présente Entente;
      </li>
      <li>    vous êtes âgé(e) d’au moins 13 ans;
      </li>
      <li>    vous n’êtes pas, de ce fait, en violation d’une autre entente ou obligation ou disposition légale ou règlementaire;
      </li>
      <li>    vous utiliserez l’application en conformité avec les présentes Modalités;
      </li>
      <li>    tous les renseignements personnels que vous fournirez sont exacts, à jour et complets;
      </li>
      <li>    vous protégerez vos renseignements personnels et ne divulguer aucun nom d’utilisateur, mot de passe ou autre information qui vous est fournie à une tierce personne, et que vous nous aviserez, dès que possible, si vous constatez ou suspectez, pour quelque raison que ce soit, que la sécurité de votre compte a été compromise; et
      </li>
      <li>    vous acceptez l’entière responsabilité pour toutes évaluations, taxes, frais et droits et toute autre responsabilité financière pouvant résulter de votre utilisation de l’Application sous votre nom ou avec votre compte, de même que toute utilisation sous votre nom ou avec votre compte par une tierce personne.
      </li>
    </ul>

    <h3>    Utilisation restreinte
    </h3>
    <p>    Vous ne pouvez pas accéder à ou utiliser l’Application pour une raison illégale ou interdite par la présente Entente. Vous pouvez accéder à ou utiliser l’Application afin d’enregistrer visuellement ou vocalement une rencontre avec une personne dans une position d'autorité, soit un policier ou une policière.
    </p>
    <p>    Cette application est régie par les lois de la province de Québec, les lois du Canada et toute législation qui sont applicables dans cette province. Si l’Application est utilisée ailleurs qu’au Canada, nous ne pouvons garantir la viabilité ou l’admissibilité de la preuve.
    </p>
    <p>    L’utilisation de cette Application pour toute autre raison, incluant dans un but commercial, est strictement interdite.
    </p>
    <p>    Sans limiter la portée générale de ce qui précède, vous acceptez qu’en accédant à ou en utilisant Notre Application, vous ne commettez ou ne participerez à, ou ne permettrez à personne de commettre ou de participer en votre nom à, aucun des actes suivants, chacun étant expressément interdit par cette Entente :
    </p>
    <ul>
      <li>    (a) faire et distribuer des copies de l’Application; (b) tenter de copier, reproduire, altérer, modifier, faire de l’ingénierie inverse, désassembler, décompiler ou traduire l’Application; (c) créer des travaux dérivés de l’Application (d) d'essayer d’extraire le code source de l’Application; (e) retirer, modifier ou détruire des notations de marques ou de droits réservés ou des avis de droits d’auteur mis sur ou contenus dans l’Application ou (f) utiliser l’Application afin de développer une application  ou une technologie ayant la même fonction première que Notre Application;
      </li>
      <li>    adopter tout autre comportement ou toute autre activité qui entre en conflit avec la présente Entente ou que BCBC considère, à sa seule et absolue discrétion, être en conflit avec cette Entente.
      </li>
    </ul>

    <h3>Plateforme de l’Application</h3>
    <p>    Cette application est uniquement disponible sur les appareils mobiles iPhone®, ainsi que sur les appareils mobiles Android®.
    </p>
    <p>    Vous reconnaissez et acceptez que la disponibilité de l’Application dépende de la plateforme fournie par une tierce partie par exemple, le « Apple Store » pour l’iPhone (« la Plateforme »), grâce à laquelle vous accédez à l’Application. Vous reconnaissez que la présente Entente est entre vous et BCBC et qu’il n’y a aucun lien contractuel avec la Plateforme. BCBC et la Clinique juridique du Grand  Montréal, et non la Plateforme, sont seuls responsables de l’Application, de son contenu, de l’entretien et des services de support, ainsi que des réclamations qui pourraient s’y rapporter par exemple, responsabilité reliée au produit, conformité règlementaire ou violation de droits de propriété intellectuelle.
    </p>

    <h3>Règles d’usage, droits et responsabilités additionnels propres à votre appareil mobile</h3>
    <p>    Sans limitée la portée générale de ce qui précède, votre utilisation de l'Application avec tout appareil mobile Apple® est assujettie aux règles d’usage établies par Apple Inc., y compris les modalités énoncées dans les modalités et conditions d’utilisation de l'App Store disponibles à l’adresse suivante
      <a href="http://www.apple.com/legal/itunes/ww/ ">http://www.apple.com/legal/itunes/ww/ </a> , les dispositions applicables étant incorporées à l’Entente par le biais de la présente référence.
    </p>
<p>    Sans limitée la portée générale de ce qui précède, votre utilisation de l'Application avec tout appareil mobile Android® est assujettie aux règles d’usage établies par Google Inc., y compris les modalités énoncées dans les modalités et conditions d’utilisation d’Android Market disponibles à l’adresse suivante
  <a href="https://play.google.com/intl/fr_lu/about/play-terms.html">https://play.google.com/intl/fr_lu/about/play-terms.html</a>  , les dispositions applicables étant incorporées à l’Entente par le biais de la présente référence.
</p>

    <h3>Limite de la responsabilité</h3>
<p>    Bien que BCBC s’engage à prendre toutes les mesures raisonnables dans les circonstances afin de maintenir la sécurité de cette Application et de protéger vos renseignements personnels et confidentiels que vous aurez fournis, vous acceptez que votre utilisation de cette Application se fait entièrement à vos propres risques. Dans les limites permises par la loi, BCBC ne sera aucunement tenue responsable de (a) toute erreur ou omission dans le contenu disponible sur l’Application et de (b) tout dommage, direct, indirect, particulier, punitif ou exemplaire suite à une perte ou un préjudice de toute nature que ce soit, notamment un dommage à l’appareil mobile, résultant de l’accès à ou de votre utilisation de l’Application. Advenant qu’un tribunal retienne la responsabilité de BCBC pour un préjudice subi, BCBC ne pourra en aucun temps être tenue responsable pour des dommages excédant la somme de 250$ CAD.
</p>

    <h3>    Exclusion de garanties
    </h3>
<p>    L’Application vous est fournie « telle qu’elle » et « telle que disponible » sans aucune représentation, caution, condition ou garantie d’aucune sorte, explicite ou implicite, ce qui comprend, sans s’y limiter, les garanties implicites de qualité marchande, d’adéquation à une fin particulière ou d’absence de contrefaçon. Cette exclusion ne s'applique pas là où la loi n'admet pas l'exclusion de garanties implicites. Dans la mesure permise par la loi, BCBC ne fait aucune représentation et garantie à l’effet que l’Application (a) ne comporte pas d’erreur, est complète ou à jour; (b) sera toujours disponible; (c) répondra à vos attentes; et (d) est sécuritaire, sans fautes, défectuosités, virus ou tout autre contenu nuisible.
</p>

    <h3>Indemnisation</h3>
    <p>    Comme condition d’utilisation de l’Application, vous acceptez de défendre, d’indemniser et de dégager BCBC, de même que tout dirigeant, administrateur, employé et mandataire de la société, de toutes responsabilités, pertes, réclamations et dépenses (y compris les frais raisonnables d’honoraires légaux) découlant de ou en relation avec votre utilisation de l’Application ou de votre violation de la présente Entente.
    </p>
    <p>    Un retard, une omission ou un défaut de BCBC d’exercer tout droit ou pouvoir découlant de cette Entente ne doit pas être considéré comme une renonciation de Notre part à exercer ce droit ou pouvoir qui pourra être exercé en tout temps et contre toute personne.
    </p>

    <h3>Liens externes</h3>
    <p>    Cette Application peut contenir des hyperliens vers d’autres sites qui sont opérés par des tierces parties. Ces liens sont fournis pour référence seulement. Nous n’avons aucun contrôle sur leur contenu ou leur performance et ne sommes pas responsables de leur contenu ou de leurs pratiques notamment, en matière de protection des renseignements personnels. BCBC n’endosse et n’accepte aucune responsabilité en lien avec le matériel qui se trouve sur ces sites externes, ni aucune association avec leur opérateur. Lorsque vous cliquez sur un lien, vous devez vous référer à leurs conditions d’utilisation et à leur politique de vie privée.
    </p>

    <h3>Lois applicables et élection de domicile</h3>
    <p>    L’Entente est à tous égards régie et interprétée conformément aux lois de la province de Québec, au Canada, et aux lois du Canada y applicables, sans égard aux lois, règles ou autres dispositions relatives aux conflits de lois (de la province de Québec ou de toute autre juridiction) qui résulteraient de l'application des lois de toute autre juridiction que celle de la province de Québec.
    </p>
    <p>    Vous acceptez d’élire domicile sous la juridiction des tribunaux du district judiciaire de Montréal, en la province de Québec, pour tout conflit ou désaccord pouvant résulter de la présente Entente. Vous renoncez à soulever toute objection quant à la compétence de ces tribunaux d'interpréter le présent contrat et d'instruire tout litige en découlant en raison d’un forum inapproprié ou pour toute autre raison, et à entreprendre ou à déposer une action ou une plainte auprès de tout autre tribunal.
    </p>
    <p>    Rien de ce que contient la présente section ne saurait empêcher BCBC d’entreprendre une action devant tout tribunal compétent dans le but d’appliquer cette Entente et de demander des dommages ou une compensation équitable suivant une violation par vous de la présente Entente.
    </p>

    <h3>Résiliation de compte</h3>
    <p>    Nous nous réservons le droit, à notre seule discrétion, de retirer ou de modifier l’Application et/ou d’empêcher l’accès ou de retirer le droit d’accès à l’Application à toute personne, à tout moment, pour quelque raison que ce soit, notamment suite à une violation des présentes Modalités.
    </p>

    <h3>Modifications</h3>
    <p>    BCBC se réserve le droit de modifier ces Modalités à tout moment pour quelque raison que ce soit. Lorsque des changements importants auront été apportés, BCBC affichera les modalités et conditions d’utilisation révisées sur l’Application. Vous serez présumés les avoir acceptées si vous continuez à utiliser l’Application après que les Modalités révisées aient été affichées.
    </p>

    <h3>Entente complète</h3>
    <p>    Sous réserve de toute autre entente applicable à laquelle BCBC pourrait être partie, ceci représente l’entente, le contrat et l’accord dans son exhaustivité entre vous et BCBC.
    </p>

    <h3>Dissociabilité</h3>
    <p>    Si toute partie de l’Entente devait être considérée comme invalide ou inexécutable, alors cette partie n’affectera pas la validité ou l’application du reste de l’Entente qui sera exécutée, et cette partie invalide ou inapplicable devra être séparée de l’Entente.
    </p>

    <h3>Nous joindre</h3>
    <p>    Pour toute question au sujet de l’Application, ou si vous avez des questions ou suggestions d’améliorations à apporter à l’Application, veuillez s’il vous plaît nous contacter à {{emailCjgm}} ou en repérant nos coordonnées sur le site web https://cjgm.ca/
    </p>
  </div>
</div>

