import { Timestamp } from '@angular/fire/firestore';
import { SimpleUser } from './account.model';


export interface Doc {
    id?: string | null;
    _createdAt?: Timestamp | null;
    _createdBy?: SimpleUser;
    _updatedAt?: Timestamp | null;
    _updatedBy?: SimpleUser;
    _deletedAt?: Timestamp | null;
    _deletedBy?: SimpleUser;
    _isDelete?: boolean;
}

export const defaultDoc = {
    _createdAt:  null,
    _createdBy: {
        id:  null
    },
    _updatedAt:  null,
    _updatedBy: {
        id:  null
    },
    _deletedAt:  null,
    _deletedBy: {
        id:  null
    },
    _isDelete: false
} as Doc;
