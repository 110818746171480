export const Const = {
  app: {
    emailCom: 'communications@cjgm.ca',
    emailCjgm: 'direction@cjgm.ca',
    user: {
      localstorage: 'bcbc_user_logged',
      minAge: 18,
      maxAge: 100
    },
  },
  firebase: {
    collections: {
      account: 'accounts',
      audio: 'audios',
      video: 'videos',
      surveys: 'surveys',
    },
  },
  roles: {
    admin: 'ADMIN',
    member: 'MEMBER',
  },
  coordsDefault: {
    lng: 11.48022226405379,
    lat: 3.843249609352396
  },
  coordsCameroon: {
    east: 16.1944081,
    north: 13.083335,
    south: 1.6559,
    west: 8.3936001
  },
  markerColor: {
    // audio: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    video: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
    audio: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
    // recovred: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
  }
};
