import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, catchError, of, tap } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { FirebaseError } from "firebase/app";
import { ToastrService } from "ngx-toastr";
import { AudioActionTypes, ErrorAudio, ListAudioSuccess, UpdateAudioSuccess } from "@Actions/audio.action";
import { AudioService } from "@Services/audio.service";
import { Audio } from "@Models/audio.model";


@Injectable()
export class AudioEffects {

    constructor(
        private actions$: Actions,
        private audioService: AudioService,
        private logger: NGXLogger,
        private toastrService: ToastrService,
    ) {}

    listAudios$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudioActionTypes.LIST_AUDIO),
            mergeMap((action: any) => {
                return this.audioService.getAudios({ isDeleted: false })
                    .pipe(
                        map((audios: Audio[]) => {
                            this.logger.info('Liste des audio', audios.length);
                            return new ListAudioSuccess(audios);
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error('login fail', err.code, err.message);
                            return of(new ErrorAudio("Une erreur est survenue lors du chargement des audios"));
                        })
                    )
            })
        )
    );

    updateAudio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudioActionTypes.UPDATE_AUDIO),
            mergeMap((action: any) => {
                return this.audioService.updateAudio(action.payload)
                    .pipe(
                        map((result) => {
                            this.logger.info("mise a jour de l'audio", action.payload);
                            return new UpdateAudioSuccess("Modification reussie");
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error('login fail', err.code, err.message);
                            return of(new ErrorAudio("Une erreur est survenue lors du chargement des audios"));
                        })
                    )
            })
        )
    );

    updateAudioSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AudioActionTypes.UPDATE_AUDIO_SUCCESS),
        tap((action: any) => this.toastrService.success(action.payload))
    ), { dispatch: false });

    errorAudio$ = createEffect(() => this.actions$.pipe(
        ofType(AudioActionTypes.ERROR_AUDIO),
        tap((action: any) => this.toastrService.error(action.payload))
    ), { dispatch: false });
}