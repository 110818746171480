import { Account } from '@Models/account.model';
import { AuthService } from '@Services/auth.service';
import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable}  from 'rxjs';
import { Logout } from 'src/app/core/store/actions/auth.action';
import { FirebaseError } from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { AuthState } from '@States/auth.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  private destroyRef = inject(DestroyRef);
  user: Account | undefined;
  acount$: Observable<Account> | undefined;

  constructor(
    private authService: AuthService,
    private store: Store<AuthState>,
    private loggerService: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.acount$ = this.authService.currentUser$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((err: FirebaseError) => {
          this.loggerService.error(err.message);
          throw new Error(err.message)
        })
      );
  }

  toggleSidebar() {
    this.toggleSidebarForMe.emit();
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}

