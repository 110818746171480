
export const environment = {
   production: false,
   useEmulators: false,
   encryptSecretKey: 'jesuistropbonpourmoimeme',
   firebase: {
    apiKey: 'AIzaSyB9SgT___164XPCTLZ3yIpDRL_MSlOH1Fg',
    authDomain: 'bcbc-dev.firebaseapp.com',
    projectId: 'bcbc-dev',
    storageBucket: 'bcbc-dev.appspot.com',
    messagingSenderId: '610267634256',
    appId: '1:610267634256:web:4a6b5625412a3439434f55',
    measurementId: 'G-KQCSTRF26N'
  }
};
