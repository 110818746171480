import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, catchError, of, tap, finalize } from "rxjs";
import { AuthService } from "@Services/auth.service";
import { Const } from "src/environments/const";
import { AUTH } from "src/app/constants/auth.constant";
import { Router, mapToResolve } from "@angular/router";
import { ROLE_VALUE } from "@Models/enums/account.enum";
import { NGXLogger } from "ngx-logger";
import { FirebaseError } from "firebase/app";
import { ToastrService } from "ngx-toastr";
import { 
    AuthActionTypes, 
    LoginSuccess, 
    AuthError, 
    LoadCurrentUser, 
    RegisterSuccess, 
    SendEmailActiveAccount, 
    SendEmailActiveAccountSuccess, 
    LogoutSuccess, 
    LoadCurrentUserSuccess, 
    ChangePasswordSuccess,
    Logout,
    ChangePassword} from "@Actions/auth.action";

const EMAIL_CHANGE_NEEDS_VERIFICATION = 'auth/email-change-needs-verification';
const EMAIL_EXISTS = 'auth/email-already-in-use';
const DEFAULT_ERROR_MESSAGE_CREATE_ACCOUNT = "Une erreur est survenue lors de la création de compte";

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private logger: NGXLogger,
        private router: Router,
        private toastrService: ToastrService,
    ) {}

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.LOGIN),
            mergeMap((action: any) => {
                return this.authService.signIn(action.payload.email, action.payload.password)
                    .pipe(
                        map(result => {
                            // @ts-ignore
                            const currentUserAuth = result.user.multiFactor.user;
                            
                            /*if (!currentUserAuth.emailVerified) {
                                this.logger.error("Email not verified.", currentUserAuth);
                                throw new Error("Une erreur est survenue");
                            }*/
                            this.logger.info('login success', currentUserAuth);
                            return new LoginSuccess();
                        }),
                        catchError((error: any) => {
                            this.logger.error('login fail', `${error.code}: ${error.message}`);
                            const messageTmp = error.message;
                            let message = "Une erreur est survenue lors de la connexion. Si l'erreur perciste veillez contacter le soutient technique.";
                            
                            if(messageTmp.includes("auth/wrong-password") || messageTmp.includes("auth/user-not-found")) {
                                message = "Email ou mot de passe incorrect";
                            }
                            return of(new AuthError(message));
                        })
                    )
            }),
            catchError((err) => {
                return of(new AuthError(err.message));
            })
        )
    );

    // Redirect to /admin after loginSuccess
    loginSuccess$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActionTypes.LOGIN_SUCCESS),
            mergeMap((action: any) => {
                return of(new LoadCurrentUser());
            }),
        )
    );

    register$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.REGISTER),
            mergeMap((action: any) => {
                return this.authService.signUp(action.payload)
                    .pipe(
                        map(result => {
                            let currentUserAuth = result.user.multiFactor.user;
                        
                            const account = {
                                ...action.payload,
                                id: currentUserAuth.uid,
                                roles: [ROLE_VALUE.BCBC_MEMBER],
                            };
                            delete account.password;
                            delete account.confirmPassword;

                            return new RegisterSuccess(account);
                        }),
                        catchError((error: FirebaseError) => {
                            this.logger.error('error occurred when creating a new account', `${error.code}: ${error.message}`);
                            let message = DEFAULT_ERROR_MESSAGE_CREATE_ACCOUNT;

                            if (error.code == EMAIL_EXISTS) {
                                message = "L'email existe déjà";
                            } else if (error.code == EMAIL_CHANGE_NEEDS_VERIFICATION) {
                                message = "Ce compte n'a toujours pas été vérifié via son email";
                            }
                            
                            return of(new AuthError(message));
                        })
                    )
            })
        )
    );

    registerSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.REGISTER_SUCCESS),
            mergeMap((action: any) => {
                let account = {
                    ...action.payload,
                    _createdAt: this.authService.timestamp,
                    _createdBy: { id: action.payload.id! }
                };

                return this.authService.createAccount(account)
                    .pipe(
                        map(result => {
                            const account = {
                                ...action.payload,
                                roles: [ROLE_VALUE.BCBC_MEMBER],
                            };
                            delete account.password;
                            delete account.confirmPassword;
                            this.logger.info("Account created in authentication service", account);
                            
                            return new SendEmailActiveAccount();
                        }),
                        catchError((error: FirebaseError) => {
                            this.logger.error(
                                "Une erreur est survenue lors de la création de compte dans la collection account", 
                                error.code, 
                                error.message
                            );
                            return of(new AuthError(DEFAULT_ERROR_MESSAGE_CREATE_ACCOUNT));
                        })
                    )
            }),
        )
    );

    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.CHANGE_PASSWORD),
            mergeMap((action: any) => {
                return this.authService.changePassword(action.payload.currentPassword, action.payload.password)
                    .pipe(
                        map(result => {
                            return new ChangePasswordSuccess();
                        }),
                        catchError((error: FirebaseError) => {
                            this.logger.error(
                                "Une erreur est survenue lors de la modification du mot de passe", 
                                error.code, 
                                error.message
                            );
                            const messageTmp = error.message;
                            let message = "Une erreur est survenue lors de la connexion. Si l'erreur perciste veillez contacter le soutient technique.";
                            
                            if(messageTmp.includes("auth/wrong-password") || messageTmp.includes("auth/user-not-found")) {
                                message = "Mot de passe incorrect";
                            }
                            return of(new AuthError(message));                 
                        })
                    )
            })
        )
    );

    changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.CHANGE_PASSWORD_SUCCESS),
        tap((action: any) => {
            this.logger.info("Modification du mot de passe avec succes");
            this.toastrService.success("Vous avez modifié votre mot de passe avec succès");
        }),
    ), { dispatch: false });

    sendEmailActiveAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.SEND_EMAIL_ACTIVE_ACCOUNT),
            mergeMap((action: any) => {
                return this.authService.sendVerificationMail()
                    .pipe(
                        map(result => {
                            this.logger.info("Email de validation envoyé avec succes", result);
                            return new SendEmailActiveAccountSuccess("Compte créé avec succès");
                        }),
                        catchError((err: any) => {
                            this.logger.error("erreur lors de l'envoie de l'email de validation", err.code, err.message);
                            return of(new AuthError(err.message));
                        })
                    )
            })
        )
    );

    sendEmailActiveAccountSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.SEND_EMAIL_ACTIVE_ACCOUNT_SUCCESS),
        tap(() => this.router.navigate(['/']))
    ), { dispatch: false });

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.LOGOUT),
            mergeMap((action: any) => {
                return this.authService.signOut()
                .pipe(
                    map(result => {
                        localStorage.removeItem(Const.app.user.localstorage);
                        this.authService.setAuthPersistance(AUTH.PERSISTENCE.NONE);
                        return new LogoutSuccess();
                    }),
                    catchError((err: any) => {
                        return of(new AuthError(err.message));
                    })
                )
            })
        )
    );

    // Redirect to / after logoutSuccess
    logoutSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        tap(() => {
            window.location.href = "/";
        })
    ), { dispatch: false });

    loadCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.LOAD_CURRENT_USER),
            mergeMap((action: any) => {
                return this.authService.currentUser$
                    .pipe(
                        map(result => {
                            return new LoadCurrentUserSuccess(result);
                        }),
                        catchError((err: FirebaseError) => {
                            this.logger.error(err.message);
                            this.logger.error("erreur de load account", err.code, err.message);
                            return of();
                            //return of(new AuthError("Une erreur est survenue"));
                        })
                    )
            })
        )
    );

    errorAccount$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.AUTH_ERROR),
        tap((action: any) => this.toastrService.error(action.payload))
    ), { dispatch: false });
}