import { AuthService } from '@Services/auth.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate  {
  constructor(
      private router: Router,
      private afAuth: AngularFireAuth,
      private authService: AuthService,
  ) {}

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

      return new Promise((resolve) => {
          this.afAuth.authState.subscribe((result) => {
              if (result && this.authService.isCurrentUserHaveOneAllAdmin) {
                  resolve(true);
              } else {
                  resolve(false);
                  this.router.navigate(['/access-denied']);
              }
          },
          error => {
              this.router.navigate(['/access-denied']);
              resolve(false);
          });
      });
  }
}