import { AuthActionTypes } from "../actions/auth.action";
import { AuthState, initialAuthState } from "@States/auth.state";

export function authReducer(
    state = initialAuthState,
    action: any
): AuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN: {
            return {
                ...state,
                loading: true,
                payload: {
                    credentials: action.payload,
                    account: null
                }
            };
        }

        case AuthActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                payload: {
                    credentials: null,
                    account: action.payload
                }
            };
        }

        case AuthActionTypes.LOAD_CURRENT_USER: {
            return {
                ...state,
                loading: true,
                payload: {
                    credentials: null,
                    account: null
                }
            };
        }

        case AuthActionTypes.LOAD_CURRENT_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                payload: {
                    credentials: null,
                    account: action.payload
                }
            };
        }

        case AuthActionTypes.LOGOUT: {
            return {
                ...state,
            };
        }

        case AuthActionTypes.LOGOUT_SUCCESS: {
            return {
                ...state,
            };
        }

        case AuthActionTypes.REGISTER: {
            return {
                ...state,
                loading: true,
            };
        }

        case AuthActionTypes.REGISTER_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case AuthActionTypes.CHANGE_PASSWORD: {
            return {
                ...state,
                payload: {
                    credentials: action.payload,
                    account: null
                },
                loading: true,
            };
        }

        case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
            debugger
            return {
                ...state,
                loading: false,
            };
        }

        case AuthActionTypes.SEND_EMAIL_ACTIVE_ACCOUNT: {
            return {
                ...state,
                loading: true,
            };
        }

        case AuthActionTypes.SEND_EMAIL_ACTIVE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            };
        }

        case AuthActionTypes.AUTH_ERROR: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }
    
        default: {
            return state;
        }
    }
}