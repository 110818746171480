import { environment } from "src/environments/environment";
import cryptojs from "crypto-js";


class UserStorageHelper {
    private secretKey: string;

    constructor(){
        this.secretKey = environment.encryptSecretKey;
    }

    public encrypt(payload: string): string {
        return cryptojs.AES.encrypt(payload, this.secretKey).toString();
    }

    public decrypt(encryptedPayload: string): string {
        const bytes = cryptojs.AES.decrypt(encryptedPayload, this.secretKey);
        return bytes.toString(cryptojs.enc.Utf8);
    }

    public store(key: string, payload: any ): void {
        payload = JSON.stringify(payload);
        const dataToStore = this.encrypt(payload);
        localStorage.setItem(key, dataToStore);
    }

    public get<T>(key: string): T | null {
        const data = localStorage.getItem(key);
        if (data) { 
            const dataToDecrypt = this.decrypt(data)
            return JSON.parse(dataToDecrypt) as T; 
        }
        return null;
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }
}

export default new UserStorageHelper();
