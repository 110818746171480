<!--  <div class="logo">-->
<!--    <img src="./assets/images/logos/logo.png" alt="logo boncop bad cop" />-->
<!--  </div>-->

<aside id="sidebar" class="sidebar">

  <ul class="sidebar-nav" id="sidebar-nav">

    <li class="nav-item">
      <a class="nav-link"
         [routerLink]="['/admin']"
         routerLinkActive="active"
         [ngClass]="{'collapsed': !isActive('/admin')}">
        <i class="bi bi-grid"></i>
        <span>Tableau de bord</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link"
         [routerLink]="['/admin/users']"
         routerLinkActive="active"
         [ngClass]="{'collapsed': !isActive('/admin/users')}">
        <i class="bi bi-person"></i>
        <span>Utilisateurs</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link"
         [routerLink]="['/admin/audios']"
         routerLinkActive="active"
         [ngClass]="{'collapsed': !isActive('/admin/audios')}">
        <i class="bi bi-volume-up"></i>
        <span>Audios</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link"
         [routerLink]="['/admin/videos']"
         routerLinkActive="active"
         [ngClass]="{'collapsed': !isActive('/admin/videos')}">
        <i class="bi bi-film"></i>
        <span>Videos</span>
      </a>
    </li>


  </ul>

</aside>
