import { Component } from '@angular/core';

@Component({
  selector: 'app-acces-denied',
  templateUrl: './acces-denied.component.html',
  styleUrl: './acces-denied.component.scss'
})
export class AccesDeniedComponent {

}
