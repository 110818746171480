import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { UserConditionComponent } from "./user-condition/user-condition.component";
import { HeaderComponent } from "./header/header.component";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { MatDialogModule } from '@angular/material/dialog';
import {MatListModule} from "@angular/material/list";
import {MatDividerModule} from "@angular/material/divider";
import { SidenavComponent } from "./sidenav/sidenav.component";
import {MatSidenavModule} from '@angular/material/sidenav';
import { RouterModule } from "@angular/router";
import { AccesDeniedComponent } from "./acces-denied/acces-denied.component";
import {MatChipsModule} from '@angular/material/chips';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FooterComponent } from './footer/footer.component';
import { environment } from "src/environments/environment";


const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  MatMenuModule,
  MatToolbarModule,
  MatDividerModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatButtonModule,
  MatListModule,
  MatSidenavModule,
  MatDialogModule,
  MatChipsModule,
  RouterModule,
  MatFormFieldModule,
  MatAutocompleteModule,
];

const COMPONENTS = [
  PrivacyPolicyComponent,
  UserConditionComponent,
  HeaderComponent,
  SidenavComponent,
  AccesDeniedComponent,
  FooterComponent
];

@NgModule({
    declarations: [
      ...COMPONENTS,
      FooterComponent
    ],
    imports: [
      ...MODULES,
      LoggerModule.forRoot({
        //serverLoggingUrl: '/api/logs',
        level: NgxLoggerLevel.DEBUG,
        enableSourceMaps: true,
        timestampFormat: 'medium',
        disableConsoleLogging: environment.production,
        serverLogLevel: NgxLoggerLevel.ERROR
      })
    ],
    exports: [
        ...COMPONENTS,
        ...MODULES
    ]
})
  export class SharedModule { }
