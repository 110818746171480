import { Action } from '@ngrx/store';
import { Video } from "@Models/video.model";

export enum VideoActionTypes {
    LIST_VIDEO = "[Video] List videos",
    LIST_VIDEO_SUCCESS = '[Video] Successfully list videos',
    UPDATE_VIDEO = "[Video] Video update",
    UPDATE_VIDEO_SUCCESS = "[Video] Successfully video update",
    ERROR_VIDEO = '[Video] Video Error'
}

// Définir le type de l'action
export class ListVideo implements Action {
    readonly type = VideoActionTypes.LIST_VIDEO;
}

export class ListVideoSuccess implements Action {
    readonly type = VideoActionTypes.LIST_VIDEO_SUCCESS;

    constructor(public payload: Video[]) {}
}

export class UpdateVideo implements Action {
    readonly type = VideoActionTypes.UPDATE_VIDEO;

    constructor(public payload: Video) {}
}

export class UpdateVideoSuccess implements Action {
    readonly type = VideoActionTypes.UPDATE_VIDEO_SUCCESS;

    constructor(public payload: string) {}
}

export class ErrorVideo implements Action {
    readonly type = VideoActionTypes.ERROR_VIDEO;
    
    constructor(public payload: string) {}
}


export type VideoActions = ListVideo | 
    ListVideoSuccess| 
    UpdateVideo | 
    UpdateVideoSuccess |
    ErrorVideo;