import { AudioState, initialAudioState } from "@States/audio.state";
import { AudioActionTypes } from "@Actions/audio.action";

export function AudioReducer(
    state = initialAudioState,
    action: any
): AudioState {
    switch (action.type) {
        case AudioActionTypes.LIST_AUDIO: {
            return {
                ...state,
                loading: true,
            };
        }

        case AudioActionTypes.LIST_AUDIO_SUCCESS: {
            return {
                ...state,
                loading: false,
                payload: {
                    audio: undefined,
                    audios: action.payload
                }
            };
        }

        case AudioActionTypes.UPDATE_AUDIO: {
            return {
                ...state,
                loading: true
            };
        }

        case AudioActionTypes.UPDATE_AUDIO_SUCCESS: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            };
        }
        

        default: {
            return state;
        }
    }
}