import { Injectable } from '@angular/core';
import {AngularFirestore, CollectionReference, Query} from "@angular/fire/compat/firestore";
import {BaseService} from "./base.service";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Observable, from, switchMap, take} from "rxjs";
import {Audio} from "@Models/audio.model";
import { Const } from 'src/environments/const';

const PATH_FIREBASE_AUDIO = Const.firebase.collections.audio;

@Injectable({
  providedIn: 'root'
})
export class AudioService extends BaseService {

  constructor(
    protected override angularFirestore: AngularFirestore,
    protected override angularFireAuth: AngularFireAuth,
  ) {
    super(angularFireAuth, angularFirestore);
  }

  getAudios(params: { isDeleted: boolean; limit?: number }): Observable<Audio[]> {
    return this.col$<Audio>(`${PATH_FIREBASE_AUDIO}`, ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('_isDeleted', '==', params.isDeleted)
        .orderBy('_createdAt', 'desc')
        .limit((params.limit) ? params.limit : 1000);

      return query;

    });
  }

  updateAudio(audio: Audio): Observable<void> {
    return from(this.update<Audio>(PATH_FIREBASE_AUDIO, audio));
  }

  deleteAllAudiosByOwner(ownerId: string): Observable<void> {
    return this.col$<Audio>(PATH_FIREBASE_AUDIO, ref => {
      let query: CollectionReference | Query = ref;
      query = query.where('ownerId', '==', ownerId);
      return query;
    }).pipe(
      take(1),
      switchMap(snapshot => {
        const deleteBatch = this.angularFirestore.firestore.batch();
        snapshot.forEach(doc => {
          const audioRef = this.angularFirestore.firestore.collection(PATH_FIREBASE_AUDIO).doc(doc.id);
          deleteBatch.delete(audioRef);
        });
        return deleteBatch.commit();
      })
    );
  }
}
