import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Const } from 'src/environments/const';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  readonly emailCom = Const.app.emailCom;
  readonly emailCjgm = Const.app.emailCjgm;

  constructor(
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.logger.info('init');
  }

}
