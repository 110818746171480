import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import { Const } from 'src/environments/const';
import {defaultUser, Account} from "@Models/account.model";
import {combineLatest, from, Observable} from "rxjs";
import firebase from "@firebase/app-compat";
import { NGXLogger } from 'ngx-logger';
import { AuthService } from './auth.service';
import { AudioService } from './audio.service';
import { VideoService } from './video.service';


const PATH_FIREBASE_ACCOUNT = Const.firebase.collections.account;

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor(
    protected override angularFireAuth: AngularFireAuth,
    protected override angularFirestore: AngularFirestore,
    private authService: AuthService,
    private audioService: AudioService,
    private videoService: VideoService,
    private logService: NGXLogger
  ) {
    super(angularFireAuth, angularFirestore);
  }

  async createUser(user: Account): Promise<void>{
    // @ts-ignore
    return this.angularFirestore.collection(`${PATH_FIREBASE_ACCOUNT}`).doc(user.id).set({
      ...defaultUser,
      ...user
    });
  }

  updateAccount(user: Account): Observable<any>{
    return from(this.update<Account>(PATH_FIREBASE_ACCOUNT, user));
  }

  getAccounts(): Observable<Account[]> {
    return this.col$<Account>(`${PATH_FIREBASE_ACCOUNT}`);
  }

  getAccountById(userId: string): Observable<Account> {
    return this.doc$<Account>(
      `${PATH_FIREBASE_ACCOUNT}/${userId}`
    );
  }

  updateAccountEmail(currentPassword: string, newEmail: string): Observable<any> {
    return from(new Promise<void>((resolve, reject) => {
        const userP = this.angularFireAuth.currentUser;
        userP.then(user=>{
            if (user) {
                this.authService.reauthenticateUser(user.email!, currentPassword)
                    .then(() => {
                        return user.updateEmail(newEmail)
                            .then(() => {
                                this.logService.info(`Email modification for account successful`);
                                user.sendEmailVerification();
                                resolve();
                            })
                            .catch((error: any) => {
                                this.logService.error("Failed to update email:", `${error.code}: ${error.message}`);
                                reject(new Error('Failed to update email: ' + error.message));
                            });
                    })
                    .catch((error: any) => {
                        this.logService.error("Failed to re-authenticate user", `${error.code}: ${error.message}` );
                        reject(new Error('Failed to re-authenticate user: ' + error.message));
                    });
            } else {
                this.logService.error("Failed to update email. No authenticated user found");
                reject(new Error('No authenticated user found.'));
            }
        });
    }));
  }

  deleteAllDataFromAccount(idAccount: string): Observable<any> {
    return combineLatest([
      this.audioService.deleteAllAudiosByOwner(idAccount),
      this.videoService.deleteAllVideosByOwner(idAccount)
    ]);
  }
}
