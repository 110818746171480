import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './main/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './main/layouts/main-layout/main-layout.component';
import { AuthGuard } from '@Guards/auth.guard';
import { AdminGuard } from '@Guards/admin.guard';
import { PrivacyPolicyComponent } from './main/common/privacy-policy/privacy-policy.component';
import { UserConditionComponent } from './main/common/user-condition/user-condition.component';
import { AccesDeniedComponent } from './main/common/acces-denied/acces-denied.component';

const routes: Routes = [
  { component: AuthLayoutComponent, path: '', loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule) },
  {
    component: MainLayoutComponent,
    path: 'admin',
    loadChildren: () => import('./main/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'user-privace_policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'user-conditions',
    component: UserConditionComponent
  },
  {
    path: 'access-denied',
    component: AccesDeniedComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
