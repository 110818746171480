import { Injectable } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore, CollectionReference, Query} from "@angular/fire/compat/firestore";
import { Const } from 'src/environments/const';
import {BaseService} from "./base.service";
import {from, Observable} from "rxjs";
import {Video} from "@Models/video.model";
import {Audio} from "@Models/audio.model";
import {switchMap, take} from "rxjs/operators";

const PATH_FIREBASE_VIDEO = Const.firebase.collections.video;


@Injectable({
  providedIn: 'root'
})
export class VideoService extends BaseService {

  constructor(
    protected override angularFireAuth: AngularFireAuth,
    protected override angularFirestore: AngularFirestore
  ) {
    super(angularFireAuth, angularFirestore);
  }

  getVideos(params: { isDeleted: boolean; limit?: number }): Observable<Video[]> {
    return this.col$<Video>(`${PATH_FIREBASE_VIDEO}`, ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('_isDeleted', '==', params.isDeleted)
        .orderBy('_createdAt', 'desc')
        .limit((params.limit) ? params.limit : 1000);

      return query;

    });
  }

  updateVideo(video: Video): Observable<void> {
    return from(this.update<Video>(PATH_FIREBASE_VIDEO, video));
  }

  deleteAllVideosByOwner(ownerId: string): Observable<void> {
    return this.col$<Audio>(PATH_FIREBASE_VIDEO, ref => {
      let query: CollectionReference | Query = ref;
      query = query.where('ownerId', '==', ownerId);
      return query;
    }).pipe(
      take(1),
      switchMap(snapshot => {
        const deleteBatch = this.angularFirestore.firestore.batch();
        snapshot.forEach(doc => {
          const videoRef = this.angularFirestore.firestore.collection(PATH_FIREBASE_VIDEO).doc(doc.id);
          deleteBatch.delete(videoRef);
        });
        return deleteBatch.commit();
      })
    );
  }
}
