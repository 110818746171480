<header id="header" class="header fixed-top d-flex align-items-center" *ngIf="acount$ | async let account">

  <div class="d-flex align-items-center justify-content-between">
    <a href="/admin" class="logo d-flex align-items-center">
      <img src="assets/images/logos/logo.png" alt="">
<!--      <span class="d-none d-lg-block">BCBC</span>-->
    </a>
    <i class="bi bi-list toggle-sidebar-btn"></i>
  </div>

  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">

      <li class="nav-item dropdown pe-3">

        <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
          <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle">
          <span class="d-none d-md-block dropdown-toggle ps-2">{{ account.firstName }}</span>
        </a>

        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
          <li class="dropdown-header">
            <h6>{{ account.firstName+' '+account.lastName}}</h6>
            <span>{{ account.email }}</span>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>

          <li>
            <a class="dropdown-item d-flex align-items-center"  [routerLink]="['/admin/user-profile']" routerLinkActive="active">
              <i class="bi bi-person"></i>
              <span>Mon Profile</span>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>

          <li>
            <a class="dropdown-item d-flex align-items-center" [routerLink]="['/admin/settings']"
               routerLinkActive="active">
              <i class="bi bi-gear"></i>
              <span>Preferences</span>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>

          <li>
            <a class="dropdown-item d-flex align-items-center" href="#" (click)="logout()">
              <i class="bi bi-box-arrow-right"></i>
              <span>Deconnexion</span>
            </a>
          </li>

        </ul>
      </li>

    </ul>
  </nav>

</header>

