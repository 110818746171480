import { Account } from '@Models/account.model';
import { Action } from '@ngrx/store';
import { Credential, CredentialPassword } from 'src/app/core/store/states/auth.state';

export enum AuthActionTypes {
    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Successful Login',
    LOGOUT = '[Auth] Logout',
    LOGOUT_SUCCESS = '[Auth] Successful Logout',
    REGISTER = '[Auth] Register',
    REGISTER_SUCCESS = '[Auth] Successful Register',
    CHANGE_PASSWORD = '[Auth] Change password',
    CHANGE_PASSWORD_SUCCESS = '[Auth] Successful Change password',
    LOAD_CURRENT_USER = '[Auth] Load Current Account',
    LOAD_CURRENT_USER_SUCCESS = '[Auth] Successful load Current Account',
    SEND_EMAIL_ACTIVE_ACCOUNT = '[Auth] Send email active account',
    SEND_EMAIL_ACTIVE_ACCOUNT_SUCCESS = '[Auth] Successful send email active account',
    AUTH_ERROR = '[Auth] Auth Error'
}

// Définir le type de l'action
export class Login implements Action {
    readonly type = AuthActionTypes.LOGIN;
    constructor(public payload: Credential) {}
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;
}

export class Logout implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export class LogoutSuccess implements Action {
    readonly type = AuthActionTypes.LOGOUT_SUCCESS;
}

export class LoadCurrentUser implements Action {
    readonly type = AuthActionTypes.LOAD_CURRENT_USER;
}

export class LoadCurrentUserSuccess implements Action {
    readonly type = AuthActionTypes.LOAD_CURRENT_USER_SUCCESS;
    constructor(public payload: Account) {}
}

export class Register implements Action {
    readonly type = AuthActionTypes.REGISTER;
    constructor(public payload: Account) {}
}

export class RegisterSuccess implements Action {
    readonly type = AuthActionTypes.REGISTER_SUCCESS;
    constructor(public payload: Account) {}
}

export class ChangePassword implements Action {
    readonly type = AuthActionTypes.CHANGE_PASSWORD;
    constructor(public payload: CredentialPassword) {}
}

export class ChangePasswordSuccess implements Action {
    readonly type = AuthActionTypes.CHANGE_PASSWORD_SUCCESS;
}

export class SendEmailActiveAccount implements Action {
    readonly type = AuthActionTypes.SEND_EMAIL_ACTIVE_ACCOUNT;
}

export class SendEmailActiveAccountSuccess implements Action {
    readonly type = AuthActionTypes.SEND_EMAIL_ACTIVE_ACCOUNT_SUCCESS;
    constructor(public payload: string) {}
}

export class AuthError implements Action {
    readonly type = AuthActionTypes.AUTH_ERROR;
    constructor(public payload: string) {}
}


export type AuthActions = Login | 
LoginSuccess | 
Logout |
LogoutSuccess |
LoadCurrentUser |
LoadCurrentUserSuccess |
Register |
RegisterSuccess | 
SendEmailActiveAccount |
SendEmailActiveAccountSuccess |
ChangePassword |
ChangePasswordSuccess |
AuthError;