import { Audio } from "@Models/audio.model";

export interface AudioState {
    lastUpdate: string;
    loading: boolean;
    errorMessage: string;
    successMessage: string;
    payload: {
        audio: Audio | undefined
        audios: Audio[]
    };
};

export const initialAudioState: AudioState = {
    lastUpdate: new Date().toString(),
    loading: false,
    errorMessage: "",
    successMessage: "",
    payload: {
        audio: undefined,
        audios: []
    },
};
