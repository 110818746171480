import { AccountState, initialAccountState } from "src/app/core/store/states/account.state";
import { AccountActionTypes } from "../actions/account.action";

export function AccountReducer(
    state = initialAccountState,
    action: any
): AccountState {
    switch (action.type) {
        case AccountActionTypes.GET_ACCOUNTS: {
            return {
                ...state,
                loading: true,
            };
        }

        case AccountActionTypes.GET_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                payload: {
                    account: undefined,
                    accounts: action.payload
                }
            };
        }

        case AccountActionTypes.GET_ACCOUNT: {
            return {
                ...state,
                loading: true
            };
        }

        case AccountActionTypes.GET_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loading: false,
                payload: {
                    account: action.payload,
                    accounts: []
                }
            };
        }

        case AccountActionTypes.UPDATE_ACCOUNT: {
            return {
                ...state,
                loading: true
            };
        }

        case AccountActionTypes.UPDATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            };
        }

        case AccountActionTypes.UPDATE_ACCOUNT_EMAIL: {
            return {
                ...state,
                loading: true
            };
        }

        case AccountActionTypes.UPDATE_ACCOUNT_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            };
        }

        case AccountActionTypes.UPDATE_ACCOUNT_PASSWORD: {
            return {
                ...state,
                loading: true,
            };
        }

        case AccountActionTypes.UPDATE_ACCOUNT_PASSWORD_SUCCESS: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            };
        }

        case AccountActionTypes.DELETE_ALL_DATA_ACCOUNT: {
            return {
                ...state,
                loading: true,
            };
        }

        case AccountActionTypes.ERROR_ACCOUNT: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }
    
        default: {
            return state;
        }
    }
}