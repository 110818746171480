import { Component, OnInit } from '@angular/core';
import { Const } from 'src/environments/const';

@Component({
  selector: 'app-user-condition',
  templateUrl: './user-condition.component.html',
  styleUrls: ['./user-condition.component.scss']
})
export class UserConditionComponent implements OnInit {
  readonly emailCom = Const.app.emailCom;
  readonly emailCjgm = Const.app.emailCjgm;

  constructor() { }

  ngOnInit() {
  }

}
