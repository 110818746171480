// Enum Role
export enum ROLE_VALUE {
    BCBC_MEMBER = "MEMBER",
    BCBC_ADMIN = "ADMIN",
    BCBC_SUP_ADMIN = "SUP_ADMIN"
  }

  export enum ROLE_NAME {
    BCBC_MEMBER = "membre",
    BCBC_ADMIN = "administrateur",
    BCBC_SUP_ADMIN = "super administrateur"
  }

  export const ROLES_NAME_VALUE = [
    { value: ROLE_VALUE.BCBC_MEMBER, viewValue: ROLE_NAME.BCBC_MEMBER },
    { value: ROLE_VALUE.BCBC_ADMIN, viewValue: ROLE_NAME.BCBC_ADMIN },
    { value: ROLE_VALUE.BCBC_SUP_ADMIN, viewValue: ROLE_NAME.BCBC_SUP_ADMIN },
  ]

// Enum genre

export enum GENDER_VALUE {
    OTHER = "other",
    MAN = 'man',
    WOMAN = 'woman'
}

export enum GENDER_NAME {
    OTHER = "enum.gender.other",
    MAN = "enum.gender.man",
    WOMAN = "enum.gender.woman"
}

export const GENDER_NAME_VALUE = [
  { value: GENDER_VALUE.MAN, viewValue: GENDER_NAME.MAN },
  { value: GENDER_VALUE.WOMAN, viewValue: GENDER_NAME.WOMAN },
  { value: GENDER_VALUE.OTHER, viewValue: GENDER_NAME.OTHER },
]
