<!-- ======= Footer ======= -->
<footer id="footer" class="footer fixed-bottom">
  <div class="copyright">
    &copy; Copyright <strong><span>BONCOP BADCOP</span></strong>. Tous Droits Réservés
  </div>
  <div class="credits">
    Designed by <a href="https://bogal.ca">Bogal</a>
  </div>
</footer>
<!-- End Footer -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
