import { Account } from "@Models/account.model";

export interface AccountState {
    lastUpdate: string;
    loading: boolean;
    errorMessage: string;
    successMessage: string;
    payload: {
        account: Account | undefined
        accounts: Account[]
    };
};

export const initialAccountState: AccountState = {
    lastUpdate: new Date().toString(),
    loading: false,
    errorMessage: "",
    successMessage: "",
    payload: {
        account: undefined,
        accounts: []
    },
};
