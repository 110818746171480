import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument,
    QueryFn
} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Doc} from '@Models/doc.model';
import {SimpleUser} from '@Models/account.model';
import {Timestamp} from 'firebase/firestore';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    protected user: SimpleUser | null | undefined;

    constructor(protected angularFireAuth: AngularFireAuth, protected angularFirestore: AngularFirestore) {
        this.onAnonymous();
    }

    /// Firebase Server Timestamp
    get timestamp() {
        return Timestamp.now()
    }

    /// **************
    /// Get a Reference

    fromDate(date: Date): any {
        return Timestamp.fromDate(date);
    }

    /// **************
    protected col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
        return typeof ref === 'string' ? this.angularFirestore.collection<T>(ref, queryFn) : ref;
    }

    /// **************
    /// Get Data

    protected doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
        return typeof ref === 'string' ? this.angularFirestore.doc<T>(ref) : ref;
    }

    /// **************
    protected doc$<T>(ref: DocPredicate<T>): Observable<T> {
        // @ts-ignore
        return this.doc(ref)
                    .snapshotChanges()
                    .pipe(
                        map(doc => {
                            const data = doc.payload.data() as any;
                            const id = doc.payload.id;
                            if (data) {
                                return {
                                    id,
                                    ...data
                                } as T;
                            } else {
                                return null;
                            }
                        })
                    );
    }

    protected col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<any[]> {
        return this.col(ref, queryFn).valueChanges({idField: 'id'});
    }

    protected set<T extends Doc>(ref: any, data: any) {
        return this.col(ref).doc(data.id).set({
            ...data,
            _createdAt: this.timestamp,
            _createdBy: this.user,
            _updatedAt: this.timestamp,
            _updatedBy: this.user
        } as T);
    }

    protected update<T extends Doc>(ref: any, data: any) {
        return this.col(ref).doc(data.id).update({
            ...data,
            _updatedAt: this.timestamp,
            _updatedBy: this.user
        });
    }

    protected softDelete<T extends Doc>(ref: any, data: any) {
        return this.col(ref).doc(data.id).update({
            _deletedAt: this.timestamp,
            _deletedBy: this.user
        });
    }

    protected delete<T>(ref: DocPredicate<T>): Promise<any> {
        return this.doc(ref).delete();
    }

    protected add<T>(ref: CollectionPredicate<T>, data: T): Promise<any> {
        return this.col(ref).add({
            ...data,
            _createdAt: this.timestamp,
            _createdBy: this.user
        });
    }

    /// **************
    /// Write Data
    /// **************

    public createId(): string {
        return this.angularFirestore.createId();
    }

    /// If doc exists update, otherwise set
    protected upsert<T>(ref: DocPredicate<Doc>, data: any) {
        const doc = this.doc(ref)
            .snapshotChanges()
            .pipe(first())
            .toPromise();
        return doc.then(snap => {
            // @ts-ignore
            return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
        });
    }

    private onAnonymous() {
        this.angularFireAuth.onAuthStateChanged(user => {
            if (user) {
                this.user = {id: user.uid} as SimpleUser;
            } else {
                this.user = null;
            }
        });
    }
}
